import { request, arrayReverse, formDataNormalizer } from 'Services';

const getItems = (data, listUrl, params = {}) => {
    const sendObj = {
        url: listUrl,
        data,
        type: 'GET',
        ...params,
    };

    return request.sendRequest(sendObj);
};

const defaultMessageSort = { column: 'date', direction: 'desc' };
export const requestMessages = (data, id) => {
    const requestData = { ...data, sort: data.sort || defaultMessageSort };
    return new Promise((resolve, reject) => {
        getItems(requestData, `consultations/${id}/messages`).then((res) => {
            const { items, pagination } = res[0];
            resolve({ items: arrayReverse(items), pagination });
        }, reject);
    });
};

export const requestUploadFiles = (data, path) => {
    return new Promise((resolve, reject) => {
        const params = {
            type: 'POST',
            processData: false,
            contentType: false,
        };

        getItems(formDataNormalizer({ files: data.files, client_id: data.clientId }), path, params).then((res) => {
            const { items, pagination } = res[0];
            resolve({ items: arrayReverse(items), pagination });
        }, reject);
    });
};

const save = (data, endpointUrl) => {
    const sendObj = {
        url: endpointUrl,
        data,
        type: 'POST',
    };
    return request.sendRequest(sendObj, false);
};

export const requestReferenceData = () => {
    const sendObj = {
        url: '/reference-value?size=0',
        type: 'GET',
    };
    return request.sendRequest(sendObj);
};

const requestCreate = (requestUrl) => (data) => {
    return new Promise((resolve, reject) => {
        save(data, requestUrl).then(
            (res) => resolve({ ...res[0] }),
            (error) => reject(error),
        );
    });
};


export const createConsultationRequest = requestCreate(`consultations`);
