import $ from "jquery";

export const getReferenceData = async () => {
    try {
        const data =  await $.ajax({
            url: `https://${process.env.HOST_NAME}/api/reference-value?size=0`,
            type: 'GET'
        });
        
        return data.message;
    } catch(e) {
        console.error('Can not upload ref data', e)
    }

    return [];
}
