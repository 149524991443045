import { consultationCookies } from 'Services/Cookie';
import Constants from '../Constants';

const consultationId = consultationCookies.get() || '';

export const initialState = {
    url: consultationId ? 'chat' : '',
    pref: '',
    storage_url: '/api',
    resizeChat: false,
    langPage: 'ua',
    closeChat: consultationId ? false : window.mobileAndTabletCheck(),
    closeStar: false,
};

export default function appReducer(state = initialState, action) {
    const { data } = action;
    switch (action.type) {
        case Constants.CHANGE_PAGE:
        case Constants.RESIZE_CHAT:
        case Constants.CHANGE_LANG:
        case Constants.CLOSE_CHAT:
        case Constants.PAGE_LOADING:
        case Constants.CLOSE_STAR:
            return {
                ...state,
                ...data,
            };

        default:
            return state;
    }
}
